import React, {useState} from "react";
import EventDetails from "../EventDetails/EventDetails";
import EventEditForm from "../EventEditForm/EventEditForm";
import ApiClient from "../../api/api";

const EventPage = ({initialEvent, setActiveEvent}) => {
    const [isEditing, setIsEditing] = useState(false);

    console.log(`Opened Event Page with ${initialEvent}`, `isEditing – ${isEditing}`);

    const handleSaveEvent = async () => {
        try {
            setIsEditing(false); // Выходим из режима редактирования
        } catch (error) {
            console.error("Ошибка при сохранении события:", error);
        }
    };

    return (
        <div>
            {isEditing ? (
                <EventEditForm
                    event={initialEvent}
                    setActiveEvent={setActiveEvent}
                    setIsEditing={setIsEditing}
                />
            ) : (
                <EventDetails
                    activeEvent={initialEvent}
                    setActiveEvent={setActiveEvent}
                    onEditEvent={() => setIsEditing(true)}
                />
            )}
        </div>
    );
};

export default EventPage;
