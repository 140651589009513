import React, {useState, useEffect} from "react";
import {Group, Div, Text, Avatar, Spinner, Button, Textarea} from "@vkontakte/vkui";
import bridge from "@vkontakte/vk-bridge";
import ApiClient from "../../api/api";
import sendAnalytics from "../../utils/analytics";
import "./EventDetails.css"

// Кастомный хук useMediaQuery
const useMediaQuery = (query) => {
    const [matches, setMatches] = useState(window.matchMedia(query).matches);

    useEffect(() => {
        const mediaQueryList = window.matchMedia(query);

        const updateMatches = (event) => setMatches(event.matches);

        // Добавляем слушатель изменений
        mediaQueryList.addEventListener("change", updateMatches);

        // Очищаем слушатель при размонтировании
        return () => mediaQueryList.removeEventListener("change", updateMatches);
    }, [query]);

    return matches;
};

const EventDetails = ({activeEvent, setActiveEvent, onEditEvent}) => {
    const api = new ApiClient();
    const [participantsInfo, setParticipantsInfo] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isParticipant, setIsParticipant] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);
    const [chatLink, setChatLink] = useState(null);

    // Определяем, десктоп ли это
    const isDesktop = useMediaQuery("(min-width: 1024px)");
    sendAnalytics(
        [99046428, 'reachGoal', 'open_event'],
        {pixel_code: 'VK-RTRG-1918547-8b4Dh', event: 'open_event'},
        {},
        {},
        {event: "open_event"}
    )

    const getCurrentUserInfo = async () => {
        try {
            const data = await bridge.send("VKWebAppGetUserInfo");
            setCurrentUser(data);
        } catch (error) {
            console.error("Ошибка при получении информации о пользователе", error);
        }
    };

    const getUserInfo = async (userId) => {
        try {
            const data = await bridge.send("VKWebAppGetUserInfo", {user_id: userId});
            return data;
        } catch (error) {
            console.error(`Ошибка при загрузке информации о пользователе ${userId}`, error);
            return null;
        }
    };

    useEffect(() => {
        const initializeUser = async () => {
            await getCurrentUserInfo();
        };
        initializeUser();
    }, []);

    useEffect(() => {
        if (currentUser && activeEvent?.members) {
            const isUserInEvent = activeEvent.members.some((member) => member.vk_id === currentUser.id);
            setIsParticipant(isUserInEvent);

            if (isUserInEvent) {
                api.getEventChat(activeEvent.id)
                    .then((chat) => setChatLink(chat.chat_link))
                    .catch((error) => console.error("Ошибка при получении ссылки на чат:", error));
            }
        }
    }, [activeEvent, currentUser]);

    const fetchParticipantsInfo = async () => {
        if (!activeEvent?.members?.length) return;

        const participantsData = await Promise.all(
            activeEvent.members.map(async (member) => {
                const userInfo = await getUserInfo(member.vk_id);
                return {...member, userInfo};
            })
        );

        setParticipantsInfo(participantsData);
        setLoading(false);
    };

    const handleParticipation = async () => {
        try {
            if (isParticipant) {
                await api.leaveEvent(activeEvent.id);
                setIsParticipant(false);
                setChatLink(null);
                sendAnalytics(
                    [99046428, 'reachGoal', 'leave_event'],
                    {pixel_code: 'VK-RTRG-1918547-8b4Dh', event: 'leave_event'},
                    {},
                    {},
                    {event: "leave_event"}
                )
            } else {
                await api.joinEvent(activeEvent.id);
                setIsParticipant(true);
                sendAnalytics(
                    [99046428, 'reachGoal', 'lead'],
                    {pixel_code: 'VK-RTRG-1918547-8b4Dh', event: 'lead'},
                    {pixel_code: 'VK-RTRG-1918547-8b4Dh', conversion_event: 'lead', conversion_value: 1},
                    {},
                    {event: "lead"}
                )
                const chat = await api.getEventChat(activeEvent.id);
                setChatLink(chat.chat_link);

            }

            const newActiveEvent = await api.getEvent(activeEvent.id);
            setActiveEvent(newActiveEvent);

            await fetchParticipantsInfo();
        } catch (error) {
            console.error("Ошибка при изменении статуса участия:", error);
        }
    };

    useEffect(() => {
        fetchParticipantsInfo();
    }, [activeEvent]);

    if (loading) {
        return <Spinner size="large"/>;
    }

    const styles = {
        participantsList: {
            display: "grid",
            gridTemplateColumns: "repeat(auto-fill, minmax(80px, 1fr))",
            gap: "16px",
            justifyItems: "center",
        },
        participantCard: {
            textAlign: "center",
            cursor: "pointer",
            paddingTop: "10px",
        },
        avatarContainer: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "8px",
        },
        participantName: {
            marginTop: "8px",
            textAlign: "center",
            fontSize: "14px",
        },
        fixedButtonContainer: {
            display: "flex",
            justifyContent: "center",
            margin: isDesktop ? "16px 0" : "12px 0",
        },
        fixedChatButtonContainer: {
            display: "flex",
            justifyContent: "center",
            margin: isDesktop ? "12px 0" : "16px 0",
        },
        centeredButton: {
            width: "90%",
            maxWidth: "400px",
        },
        chatButton: {
            width: "90%",
            maxWidth: "400px",
        },
    };

    const isEventCreator = currentUser?.id === activeEvent.creator.vk_id; // Проверка на авторство

    return (
        <Group>
            <Div>
                <Text weight="regular" style={{marginBottom: 16, whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
                    {activeEvent?.description}
                </Text>
                <Text weight="regular">Участники: {activeEvent?.members.length}</Text>
                <Text weight="regular">
                    Местоположение: {activeEvent?.offices.map((office) => office.name).join(", ")}
                </Text>

                <div style={{marginTop: 16}}>
                    <Text weight="regular">Список участников:</Text>
                    <div style={styles.participantsList}>
                        {participantsInfo.map((participant) => {
                            let partipicantLink = `https://vk.com/id${participant.vk_id}`
                            if (window.mobileCheck()) {
                                return <a className="open_profile" href={partipicantLink}>
                                    <div
                                        key={participant.vk_id}
                                        style={styles.participantCard}
                                    >
                                        <div style={styles.avatarContainer}>
                                            <Avatar size={64} src={participant.userInfo?.photo_100}/>
                                        </div>
                                        <Text weight="regular" style={styles.participantName}>
                                            {participant.userInfo?.first_name} {participant.userInfo?.last_name}
                                        </Text>
                                    </div>
                                </a>
                            } else {
                                return <div
                                    key={participant.vk_id}
                                    style={styles.participantCard}
                                    onClick={(c) => window.open(partipicantLink, "_blank")}
                                >
                                    <div style={styles.avatarContainer}>
                                        <Avatar size={64} src={participant.userInfo?.photo_100}/>
                                    </div>
                                    <Text weight="regular" style={styles.participantName}>
                                        {participant.userInfo?.first_name} {participant.userInfo?.last_name}
                                    </Text>
                                </div>
                            }
                        })}
                    </div>
                </div>

                {isParticipant && chatLink && (
                    <div style={styles.fixedChatButtonContainer}>
                        <Button
                            size="l"
                            mode="primary"
                            appearance="positive"
                            href={chatLink}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={styles.chatButton}
                        >
                            Открыть чат события
                        </Button>
                    </div>
                )}

                <div style={styles.fixedButtonContainer}>
                    <Button
                        size="l"
                        onClick={handleParticipation}
                        mode="primary"
                        style={styles.centeredButton}
                    >
                        {isParticipant ? "Покинуть ивент" : "Присоединиться"}
                    </Button>
                </div>

                {isEventCreator && ( // Показываем кнопку "Редактировать событие" только автору
                    <div style={{display: "flex", justifyContent: "center", margin: "16px 0"}}>
                        <Button
                            size="l"
                            mode="secondary"
                            onClick={() => onEditEvent(activeEvent)}
                            style={styles.centeredButton}
                        >
                            Редактировать событие
                        </Button>
                    </div>
                )}
            </Div>
        </Group>
    );
};

export default EventDetails;
