class ApiClient {
    constructor() {
        this.baseUrl = "https://vkruzhke-api.kfdtg.ru";
        this.authType = sessionStorage.getItem('X-Auth-Type');
        this.authToken = sessionStorage.getItem('X-Auth-Token');
    }

    getAuthHeaders() {
        if (!this.authType || !this.authToken) {
            return {};
        }
        return {
            'X-Auth-Type': this.authType,
            'X-Auth-Token': this.authToken
        };
    }

    async makeRequest(method, path, body = null, queryParams = null) {
        const url = new URL(path, this.baseUrl);

        if (queryParams) {
            Object.keys(queryParams).forEach(key => {
                if (queryParams[key] !== null) {
                    url.searchParams.append(key, queryParams[key]);
                }
            });
        }

        const options = {
            method,
            headers: {
                'Content-Type': 'application/json',
                ...this.getAuthHeaders()
            }
        };

        if (body) {
            options.body = JSON.stringify(body);
        }

        const response = await fetch(url, options);

        if (!response.ok) {
            if (response.status === 401) {
                // Clear auth data if unauthorized
                sessionStorage.removeItem('X-Auth-Type');
                sessionStorage.removeItem('X-Auth-Token');
                this.authType = null;
                this.authToken = null;
                throw new Error('Unauthorized: Please login again');
            }
            if (response.status === 422) {
                const error = await response.json();
                throw new Error(`Validation Error: ${JSON.stringify(error.detail)}`);
            }
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return await response.json();
    }

    // Users
    async registerUser(name) {
        return await this.makeRequest('POST', '/users/register', {
            name
        });
    }

    async getMe() {
        return await this.makeRequest('GET', '/users/me');
    }

    // Offices
    async getAllOffices() {
        return await this.makeRequest('GET', '/offices/all');
    }

    // Events
    async setupEvent(eventData) {
        return await this.makeRequest('POST', '/events/setup', {
            name: eventData.name,
            description: eventData.description,
            start_date: eventData.start_date,
            office_ids: eventData.office_ids
        });
    }

    async joinEvent(eventId) {
        return await this.makeRequest('POST', `/events/${eventId}/join`);
    }

    async getEventChat(eventId) {
        return await this.makeRequest('GET', `/events/${eventId}/chat`);
    }

    async leaveEvent(eventId) {
        return await this.makeRequest('POST', `/events/${eventId}/leave`);
    }

    async getEvent(eventId) {
        return await this.makeRequest('GET', `/events/${eventId}`);
    }

    async searchEvents(searchPhrase, byDate, byOffices) {
        let searchData = {};
        if (searchPhrase) {
            searchData['search_phrase'] = searchPhrase;
        }
        if (byDate) {
            searchData['by_date'] = byDate instanceof Date ? byDate.toISOString().split('T')[0] : byDate;
        }
        console.log(byOffices)
        if (byOffices && byOffices.length > 0) {
            searchData['by_offices'] = byOffices;
        }
        return await this.makeRequest('GET', '/events/search', null, searchData);
    }

    async getJoinedEvents() {
        return await this.makeRequest('GET', '/events/joined');
    }

    async getAllEvents() {
        return await this.makeRequest('GET', '/events/all');
    }

    async updateEvent(eventId, eventData) {
        return await this.makeRequest('PATCH', `/events/${eventId}`, eventData);
    }

}

export default ApiClient;